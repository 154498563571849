import React, { useState } from 'react';
import AlbumGrid from './components/AlbumGrid';
import UsernameForm from './components/UsernameForm';
import { Album } from './types';

const App: React.FC = () => {
  const [albums, setAlbums] = useState<Album[]>([]);

  const fetchAlbums = async (username: string, period: string, gridSize: number) => {
    try {
      const response = await fetch(`/api/topalbums/${username}/${period}?limit=${gridSize}`);
      if (!response.ok) {
        throw new Error('Failed to fetch albums');
      }
      const data = await response.json();
      setAlbums(data);
    } catch (error) {
      console.error('Error fetching albums:', error);
      // You might want to set an error state here and display it to the user
    }
  };

  return (
    <div className="App">
      <h1>LastFM Top Albums</h1>
      <UsernameForm onSubmit={fetchAlbums} />
      {albums.length > 0 &&
        <AlbumGrid albums={albums} />
      }
    </div>
  );
};

export default App;
