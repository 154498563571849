import React, { useState } from 'react';

interface UsernameFormProps {
  onSubmit: (username: string, period: string, gridSize: number) => void;
}

const UsernameForm: React.FC<UsernameFormProps> = ({ onSubmit }) => {
  const [username, setUsername] = useState('');
  const [period, setPeriod] = useState('overall');
  const [gridSize, setGridSize] = useState(9);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(username, period, gridSize);
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        placeholder="Enter LastFM username"
        required
      />
      <select value={period} onChange={(e) => setPeriod(e.target.value)}>
        <option value="overall">Overall</option>
        <option value="1day">Last 24 hours</option>
        <option value="7day">Last 7 days</option>
        <option value="1month">Last month</option>
        <option value="3month">Last 3 months</option>
        <option value="6month">Last 6 months</option>
        <option value="12month">Last 12 months</option>
      </select>
      <select value={gridSize} onChange={(e) => setGridSize(Number(e.target.value))}>
        <option value={9}>3x3</option>
        <option value={16}>4x4</option>
        <option value={25}>5x5</option>
        <option value={36}>6x6</option>
        <option value={49}>7x7</option>
        <option value={64}>8x8</option>
        <option value={81}>9x9</option>
        <option value={100}>10x10</option>
      </select>
      <button type="submit">Get Top Albums</button>
    </form>
  );
};

export default UsernameForm;
