import React, { useRef, useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import { Album } from '../types';

interface AlbumGridProps {
  albums: Album[];
}

const AlbumGrid: React.FC<AlbumGridProps> = ({ albums }) => {
  const gridRef = useRef<HTMLDivElement>(null);
  const [gridSize, setGridSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (gridRef.current) {
      const updateSize = () => {
        setGridSize({
          width: gridRef.current!.offsetWidth,
          height: gridRef.current!.offsetHeight
        });
      };
      updateSize();
      window.addEventListener('resize', updateSize);
      return () => window.removeEventListener('resize', updateSize);
    }
  }, [albums]);

  const downloadGrid = async () => {
    if (gridRef.current) {
      try {
        const canvas = await html2canvas(gridRef.current, {
          width: gridSize.width,
          height: gridSize.height,
          scale: 2,
          useCORS: true,
          backgroundColor: '#ffffff',
        });
        const image = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = image;
        link.download = 'lastfm-top-albums.png';
        link.click();
      } catch (error) {
        console.error('Error creating image:', error);
        alert('Failed to create image. Please try again.');
      }
    }
  };

  const gridColumns = Math.ceil(Math.sqrt(albums.length));
  const gridStyle = {
    gridTemplateColumns: `repeat(${gridColumns}, 1fr)`,
  };

  return (
    <div>
      <div ref={gridRef} className="album-grid" style={gridStyle}>
        {albums.map((album, index) => (
          <div key={index} className="album-item">
            <div className="album-image-container">
              <img
                src={album.image}
                alt={`${album.name} by ${album.artist}`}
                crossOrigin="anonymous"
              />
              <div className="album-name">{album.name}</div>
              <div className="artist-name">{album.artist}</div>
              <div className='playcount'>{album.playcount}</div>
            </div>
          </div>
        ))}
      </div>
      {albums.length > 0 && (
        <button onClick={downloadGrid} className="download-button">
          Download Grid
        </button>
      )}
    </div>
  );
};

export default AlbumGrid;
